<template>
<v-container>
  <v-row>
    <v-col cols="6">
  <div class="text-h3">
        {{ chartData.name }}
  </div>
  </v-col>
  <v-col cols="6">
  <div class="text-h3">
        {{ chartDataApo.name }}
  </div>
  </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  </v-col>    
    <v-col cols="6">
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartDataApo"
    :chart-id="chartIdApo"
    :dataset-id-key="datasetIdKeyApo"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  </v-col>
  </v-row>  
  <v-row>
    <v-col cols="12">
  <div class="text-h3">
        {{ chartDataGesamt.name }}
  </div>
  </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartDataGesamt"
    :chart-id="chartIdGesamt"
    :dataset-id-key="datasetIdKeyGesamt"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  </v-col>
  </v-row>
  </v-container>
</template>

<script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
  import axios from "axios";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

  export default {
    name: 'DashboardView',
    components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartIdApo: {
      type: String,
      default: 'line-chartApo'
    },
    datasetIdKeyApo: {
      type: String,
      default: 'labelApo'
    },
  },
  data() {
    return {
      chartData: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartDataApo: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartDataGesamt: {
        name: "",
        labels: [
        
        ],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          },
          {
            label: '',
            backgroundColor: '',
            data: []
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
        
  },
  async created() {
    this.$store.commit('setPageTitle', "page.Dashboard");

    const apiUrl = process.env.VUE_APP_API_URL;

    try {
      const response = await axios.get(apiUrl + "/data");
      
      this.chartData.name = response.data[0].name;
      this.chartData.labels = response.data[0].labels;
      this.chartData.datasets[0].data = response.data[0].chartDatasets[0].data;
      this.chartData.datasets[0].label = response.data[0].chartDatasets[0].label;      
      this.chartData.datasets[0].backgroundColor = response.data[0].chartDatasets[0].color;

      this.chartData.datasets[1].data = response.data[0].chartDatasets[1].data;
      this.chartData.datasets[1].label = response.data[0].chartDatasets[1].label;      
      this.chartData.datasets[1].backgroundColor = response.data[0].chartDatasets[1].color;

      this.chartDataApo.name = response.data[1].name;
      this.chartDataApo.labels = response.data[1].labels;
      this.chartDataApo.datasets[0].data = response.data[1].chartDatasets[0].data;
      this.chartDataApo.datasets[0].label = response.data[1].chartDatasets[0].label;      
      this.chartDataApo.datasets[0].backgroundColor = response.data[1].chartDatasets[0].color;

      this.chartDataApo.datasets[1].data = response.data[1].chartDatasets[1].data;
      this.chartDataApo.datasets[1].label = response.data[1].chartDatasets[1].label;      
      this.chartDataApo.datasets[1].backgroundColor = response.data[1].chartDatasets[1].color;

      this.chartDataGesamt.name = response.data[2].name;
      this.chartDataGesamt.labels = response.data[2].labels;
      this.chartDataGesamt.datasets[0].data = response.data[2].chartDatasets[0].data;
      this.chartDataGesamt.datasets[0].label = response.data[2].chartDatasets[0].label;      
      this.chartDataGesamt.datasets[0].backgroundColor = response.data[2].chartDatasets[0].color;

      this.chartDataGesamt.datasets[1].data = response.data[2].chartDatasets[1].data;
      this.chartDataGesamt.datasets[1].label = response.data[2].chartDatasets[1].label;      
      this.chartDataGesamt.datasets[1].backgroundColor = response.data[2].chartDatasets[1].color;


    } catch (e) {
      this.errors.push(e);
    }
  },
}
</script>
