import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from '@/layouts/default/Index'
import Home from '@/views/Dashboard.vue'
import Login from '@/views/UserLogin.vue'

Vue.use(VueRouter)

/* eslint-disable no-unused-vars */
function requireAuth(to, from, next) {
  if (!Vue.prototype.$http.defaults.headers.common["Authorization"]) {
    let token = store.state.user.token;
    if (token == '') {
      next({
      name: "Login",
    });
    }
    else {        
      if(!store.getters.getLoginState) {
        next({
          name: "Login",
        });
      }
      else {
        Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer  " + token;
      }
    }
  }

  if (to.meta.roles != null) {
    let access = false;      
    let userRole = store.getters.getRole;
    
      if(to.meta.roles.includes(userRole)) {
        access = true;
        next();
        return;
      }
      else
      {
        next({
          name: "Home",
        });
      }      
  } 
  else {
    next();
  }
}
/* eslint-enable no-unused-vars */

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      layout: Layout,      
      //roles: ['user', 'admin']
    },
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: Layout }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router