<template>
  <v-fade-transition mode="out-in">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </v-fade-transition>
</template>

<script>

import '@/styles/overrides.sass'

export default {
  name: 'App',
    metaInfo: {
      title: 'Projekt-NULL',
      titleTemplate: '%s',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },  
};
</script>
