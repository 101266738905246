<template>
  <v-container id="user-login-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <material-card color="primary" icon="mdi-account-outline">
          <template #title>{{ $t('login') }}</template>
          <form @submit.prevent="loginUser">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field 
                    label="Username"
                    v-model="login.email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="login.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    :label="$t('password')"
                    counter
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-left">
                  <v-btn color="primary" min-width="150" type="submit">
                    Login
                  </v-btn>
                </v-col>              
              </v-row>
              <v-row v-if="loginError">
                <v-col cols="12" class="text-left">
                  Username or password are not correct
                </v-col>
              </v-row>
            </v-container>
          </form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "UserLoginView",
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      showPassword: false,
      loginError: false,
    };
  },
  methods: {
    async loginUser () {
      try {
        const apiUrl = process.env.VUE_APP_API_URL;

        let response = await axios.post(apiUrl + "/api/authenticate", this.login);
        
        this.$store.commit('changeToken', response.data.token);
        this.$store.commit('changeLoggedIn', true);        
        this.$router.push("/");

      } catch (err) {
        this.loginError = true;
        console.log(err.response);
      }
    },
  },
  async created() {
    this.$store.commit('setPageTitle', "login");
  }
};
</script>
